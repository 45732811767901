




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UseInfo extends Vue {
  @Prop({
    required: true
  })
  public place!: any;

  public isTipActive: boolean = false;
  public tipToggle() {
    this.isTipActive = !this.isTipActive;
  }
}
